import { useState } from "react";
import { toast } from "react-toastify";
import { apiUrl, LoginResponse } from "../api";
import jwt_decode from "jwt-decode";

export interface User {
  username: string,
  permissions: Permission[]
  token: string
}
export type Permission = "ALL" | "getLeads";
export interface TokenPayload {
  date: number,
  permissions: Permission[]
}

interface IProps {
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
}
function Login(props: IProps) {
  const [user, setUser] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");

  const handleSubmit = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    fetch(`${apiUrl}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: user,
        password: pwd
      })
    }).then(
      async (res) => {
        if (res.status === 200) {
          const loginResponse: LoginResponse = await res.json();
          const token = loginResponse.token;
          const jwtPayload: TokenPayload = jwt_decode(token);
          props.setUser({
            username: user,
            permissions: jwtPayload.permissions,
            token: token
          });
        } else {
          toast.error(`Credenciales no válidas`);
        }
      },
      (err) => {
        console.error(err);
        toast.error(`Error interno intentando hacer login: ${err}`);
      }
    );
  }

  return <form className="app-creds-form" onSubmit={handleSubmit}>
    <label>Usuario:</label>
    <input onChange={(e) => { setUser(e.target.value); }} />
    <label>Contraseña:</label>
    <input type="password" onChange={(e) => { setPwd(e.target.value); }} />
    <div className="horizontal-separator-s" />
    <input className="form-button" type="submit" value="Submit" />
  </form>
}

export default Login;