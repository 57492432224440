import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import LeadExporter from './components/LeadExporter';
import Login, { Permission, User } from './components/Login';

function App() {
  const [user, setUser] = useState<User | undefined>(undefined);

  const userViews: { permission: Permission, view: JSX.Element }[] = [{
    permission: "getLeads",
    view: <LeadExporter user={user!}></LeadExporter>
  }];

  return (
    <div className="app-container">
      <header className="app-header">
        <div className="header-content">
          <button className="logo-button">
            <img className="logo-img" src="assets/logo/logo-gris_horizontal.png" alt={"logo"} />
          </button>
          <h1 className="page-title">
            &#9881; BACKOFFICE &#9881;
          </h1>
        </div>
      </header>
      <div className="app-content">
        {!user && <Login setUser={setUser}></Login>}
        {user && <p>Has iniciado sesión como: <b>{user.username}</b></p>}
        {userViews.flatMap((view) =>
          user?.permissions.find((p) => p === "ALL") || user?.permissions.find((p) => p === view.permission) ? [view.view] : [])}
      </div>
      <ToastContainer position="top-left" theme="colored" />
    </div>
  );
}

export default App;
