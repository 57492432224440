import { useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { apiUrl, GetLeadsResponse } from "../api";
import { ILead } from "../models";
import { User } from "./Login";
import CircleLoader from "react-spinners/CircleLoader";
import { flattenObject } from "../utils";
import DatePicker from "react-datepicker";
import "./LeadExporter.css";
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  user: User
}

function LeadExporter(props: IProps) {
  const aWeekAgo = new Date();
  aWeekAgo.setDate(aWeekAgo.getDate() - 30);
  const [date, setDate] = useState(aWeekAgo);
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadLeads = (from: Date) => {
    setDownloading(true);
    setLeads(undefined);
    fetch(`${apiUrl}/api/leads?from=${encodeURIComponent(from.toDateString())}`, {
      method: 'GET',
      headers: {
        'Authorization': props.user.token
      }
    }).then(
      async (res) => {
        if (res.status === 200) {
          const getLeadsRes: GetLeadsResponse = await res.json();
          setLeads(getLeadsRes.leads);
          setDownloading(false);
        } else {
          console.error(res.text());
          toast.error(`Error ${res.status} obteniendo leads: ${res.text()}`);
        }
      },
      (err) => {
        console.error(err);
        toast.error(`Error interno intentando obtener leads: ${err}`);
      }
    );
  }

  const filterLeads = (leads: ILead[]): Object[] => {
    return leads
      .map((l) => {
        return {
          id: l.id,
          nombre: l.name,
          apellidos: l.surname,
          email: l.email,
          telefono: l.phone,
          autorizacion_publicidad: l.allows_news ? "si" : "no",
          estado: l.custom_home.status,
          fecha: l.date,
          solar: {
            zona: l.custom_home.zone,
            localidad: l.custom_home.locality,
            direccion: l.custom_home.address,
          },
          myEcoHome: {
            prototipo: l.custom_home.prototype.id,
            modelo: l.custom_home.prototype.model,
            doble_altura: l.custom_home.prototype.doubleHeight ? "si" : "no",
            area_construida: l.custom_home.prototype.builtArea,
            precio: l.custom_home.prototype.price,
            num_plantas: l.custom_home.prototype.distribution.length,
            num_habitaciones: l.custom_home.prototype.distribution.reduce((totalRooms, { rooms }) => totalRooms + rooms, 0),
            superficies: l.custom_home.surfaces.map((s) => `${s.name}: [${s.selectors.map((sel) => `${sel.name && `${sel.name}: `}${sel.option}`).join("; ")}]`).join("\n"),
            extras: l.custom_home.extras.map((e) => e.name).join("\n"),
            ecoExtras: l.custom_home.ecoExtras.map((e) => e.name).join("\n")
          },
          UTM: JSON.stringify(l.utm).replaceAll(",", ";"),
          budget_automail_state: l.budget_automail_state
        }
      })
      .map((l) => flattenObject(l))
  }

  return <>

    <>
      <h3>Descarga de leads:</h3>
      <label>Obtener leads desde el:</label>
      <DatePicker
        selected={date}
        dateFormat='dd-MM-yyyy'
        onChange={(date: Date) => setDate(date)} />
      <div className="horizontal-separator-s"></div>
      <button className={"download-button"} onClick={() => downloadLeads(date)}>Descargar</button>
      {leads !== undefined &&
        <>
          <div className="horizontal-separator-l"></div>
          <label>Link de descarga:</label>
          <CSVLink
            data={filterLeads(leads)}
            filename={"leads.csv"}
            className={"csv-link"}
          >
            leads.csv
          </CSVLink>
        </>
      }
      <CircleLoader color="blue" loading={downloading} />
    </>

  </>
}

export default LeadExporter;