import { Permission } from "./components/Login";
import { SERVER_URL } from "./env";
import { ILead } from "./models";

export const apiUrl = SERVER_URL;

export interface LoginResponse {
  token: string
}

export interface TokenPayload {
  username: string,
  permissions: Permission[]
}

export interface GetLeadsResponse {
  leads: ILead[];
}